
import { Component, Prop, Vue } from 'vue-property-decorator'

import MixinMemberId from '~/components/mixins/MixinMemberId'
import Follow from '~/components/shared/Follow.vue'

@Component({
  components: {
    Follow
  },
  mixins: [MixinMemberId]
})
export default class CompanySmallCard extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private company: any

  @Prop({
    type: Boolean,
    required: false
  })
  private search: boolean

  @Prop({
    type: Boolean,
    required: false
  })
  private simpleCard: boolean

  @Prop({
    type: String,
    required: false
  })
  private used: string

  private memberId!: number

  private clickFollow() {
    if (!this.memberId) {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    } else {
      this.$emit('changeFollowing')
    }
  }
}
