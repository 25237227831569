
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHeader from '~/components/shared/card/CardHeader.vue'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'

@Component({
  components: {
    CardHeader,
    ArticleCard
  }
})
export default class Carousel extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  private articles: any

  @Prop({
    type: Object,
    required: true
  })
  private settings: Object
}
