
import { Component, Prop, Vue } from 'vue-property-decorator'

import ArticleCard from '~/components/shared/card/ArticleCard.vue'

@Component({
  components: {
    ArticleCard
  }
})
export default class RecommendedGroup extends Vue {
  @Prop({
    type: Array,
    required: false
  })
  private articles: any

  @Prop({
    type: String,
    required: false
  })
  private used: string
}
